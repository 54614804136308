/* ==========================================================================
   1. Root Variables & Base Styles
   ========================================================================== */
:root {
  --color-primary: #212021;
  --color-secondary: #f3d914;
  --color-white: #ffffff;
}

* {
  font-family: "Bebas Neue", sans-serif;
}

/* ==========================================================================
   2. Typography
   ========================================================================== */
h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

p {
  font-size: 1.25rem;
  line-height: 1.6;
}

/* ==========================================================================
   3. Common Section Styles
   ========================================================================== */
.section-content {
  width: 100%;
  min-height: 60vh;
  display: flex;
  align-items: center;
  padding: 2rem 0;
}

.dark {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.light {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.content-container {
  width: 100%;
  display: flex;
}

.content-half {
  width: 50%;
  padding: 5rem;
}

/* ==========================================================================
   4. Hero Section (Section 1)
   ========================================================================== */
.section-hero {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: var(--color-primary);
}

.hero-background {
  position: absolute;
  inset: 0;
  background-image: url("/assets/media/images/background.jpg");
  background-size: cover;
  background-position: center;
  opacity: 0.1;
}

/* Hero Content */
.hero-content {
  position: relative;
  height: 100%;
  display: flex;
  width: 100%;
}

.left-side,
.right-side {
  flex: 1;
  display: flex;
  height: 100%;
}

.left-side {
  justify-content: flex-end;
  padding-right: 5rem;
  align-items: center;
}

.right-side {
  justify-content: flex-start;
  padding-left: 8rem;
  align-items: center;
}

.left-content,
.right-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 19rem;
  height: 19rem;
  background-image: url("/assets/media/images/placeholder-logo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.hero-quote {
  color: var(--color-white);
  font-size: 2.5rem;
  text-align: center;
  margin: 0;
  line-height: 1;
}

.profile-image {
  width: 26rem;
  height: 26rem;
  background-image: url("/assets/media/images/profile.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* ==========================================================================
   5. About Section (Section 2)
   ========================================================================== */
.content-half.about-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-2-image {
  width: 20rem;
  height: 100%;
  min-height: 300px;
  object-fit: cover;
  object-position: center;
  display: block;
  margin: 0 auto;
  border: 5px dotted var(--color-secondary);
}

/* ==========================================================================
   6. Portfolio Section (Section 3)
   ========================================================================== */
.content-container.portfolio {
  gap: 2rem;
  padding: 2rem;
}

.content-half.portfolio-text {
  width: 30%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-half.portfolio-gallery {
  width: 70%;
  padding: 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.portfolio-image-container {
  width: calc(25% - 0.75rem);
  aspect-ratio: 1/1;
  border: 3px solid var(--color-primary);
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.portfolio-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.portfolio-image-container:hover .portfolio-image {
  transform: scale(1.05);
}

/* ==========================================================================
   7. Budget Section
   ========================================================================== */
.budget-container {
  width: 100%;
  display: flex;
  gap: 2rem;
  padding: 2rem;
}

.budget-text {
  width: 50%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.budget-info {
  width: 50%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-secondary);
  border-radius: 1rem;
}

.budget-info h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--color-primary);
}

.budget-info p {
  color: var(--color-primary);
  margin-bottom: 1rem;
}

.budget-list-text {
  list-style: none;
  padding-top: 1.5rem;
}

.budget-list-text li {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;
  color: var(--color-secondary);
}

.budget-list-text li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--color-secondary);
}

.budget-list {
  list-style: none;
  padding: 0;
}

.budget-list li {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;
  color: var(--color-primary);
}

.budget-list li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--color-primary);
}

/* ==========================================================================
   8. Map Section
   ========================================================================== */
.map-container {
  padding: 2rem;
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.business-hours {
  margin-top: 2rem;
  color: var(--color-secondary);
}

.location-tabs {
  display: flex;
  gap: 1rem;
  margin: 1rem 0 2rem 0;
}

.tab-button {
  padding: 0.75rem 1.5rem;
  background-color: transparent;
  border: 2px solid var(--color-secondary);
  color: var(--color-secondary);
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab-button.active {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.tab-button:hover {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

/* ==========================================================================
   9. Testimonials Section
   ========================================================================== */
.testimonials-title {
  text-align: center;
  margin-bottom: 3rem;
}

.content-container.testimonials {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.testimonials-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 0 2rem;
  flex-wrap: wrap;
}

.testimonial-card {
  flex: 1;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background-color: var(--color-primary);
  border-radius: 1rem;
}

.testimonial-image-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1.5rem;
  border: 3px solid var(--color-secondary);
}

.testimonial-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-stars {
  margin-bottom: 1rem;
}

.star-icon {
  color: var(--color-secondary);
  margin: 0 0.2rem;
}

.testimonial-text {
  color: var(--color-white);
  margin-bottom: 1rem;
  line-height: 1.4;
}

.testimonial-name {
  color: var(--color-secondary);
  font-size: 1.2rem;
  margin-top: auto;
}

.reviews-button {
  margin-top: 3rem;
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

.reviews-button:hover {
  background-color: var(--color-white);
}

/* ==========================================================================
   10. Final Section
   ========================================================================== */
.section-final {
  width: 100%;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 2rem 0;
}

.final-content {
  text-align: center;
  padding: 2rem;
}

/* ==========================================================================
   11. Footer
   ========================================================================== */
.footer {
  width: 100%;
  padding: 1rem;
  background-color: var(--color-primary);
  color: var(--color-white);
  text-align: center;
}

.footer a {
  color: var(--color-secondary);
}

/* ==========================================================================
   12. Common Components
   ========================================================================== */
/* CTA Buttons */
.cta-button {
  margin-top: 1rem;
  padding: 1rem 2rem;
  background-color: var(--color-primary);
  color: var(--color-secondary);
  font-size: 1.25rem;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s ease;
}

.cta-button-final {
  margin-top: 1rem;
  padding: 1rem 2rem;
  background-color: var(--color-secondary);
  color: var(--color-primary);
  font-size: 1.25rem;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s ease;
}

.cta-button:hover,
.cta-button-final:hover {
  background-color: var(--color-white);
}

.icon {
  margin-right: 0.5rem;
}

/* Gallery Modal */
.gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-image-container {
  max-width: 90%;
  max-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.gallery-modal .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  color: var(--color-white);
  font-size: 2rem;
  cursor: pointer;
  background: none;
  border: none;
  padding: 10px;
  z-index: 1001;
}

/* ==========================================================================
   13. Media Queries
   ========================================================================== */
@media (max-width: 1024px) {
  .left-side {
    padding-right: 3rem;
  }

  .right-side {
    padding-left: 3rem;
  }

  .logo,
  .profile-image {
    width: 12rem;
    height: 12rem;
  }

  .content-half {
    padding: 3rem;
  }
}

@media (max-width: 768px) {
  .section-hero {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }

  .hero-content {
    flex-direction: column-reverse;
    padding: 2rem;
    height: auto;
    justify-content: center;
  }

  .left-side,
  .right-side {
    width: 100%;
    height: auto;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  .right-side {
    margin-bottom: 1rem;
  }

  .left-content,
  .right-content {
    justify-content: center;
  }

  .logo {
    width: 10rem;
    height: 10rem;
    margin-top: -1rem;
  }

  .profile-image {
    width: 12rem;
    height: 12rem;
  }

  .hero-quote {
    font-size: 1.5rem;
    margin-top: 1rem;
  }

  .content-container {
    flex-direction: column;
  }

  .content-reverse {
    flex-direction: column;
  }

  .content-half {
    width: 100%;
    padding: 2rem;
  }

  .testimonials-container {
    flex-direction: column;
    align-items: center;
  }

  .testimonial-card {
    width: 100%;
    max-width: 400px;
    margin-bottom: 2rem;
  }

  .budget-container {
    flex-direction: column;
  }

  .budget-text,
  .budget-info {
    width: 100%;
    padding: 2rem;
  }

  .map-container {
    padding: 1rem;
  }

  .content-half.portfolio-text,
  .content-half.portfolio-gallery {
    width: 100%;
    padding: 2rem;
  }

  .content-half.portfolio-gallery {
    flex-wrap: wrap;
  }

  .portfolio-image-container {
    width: calc(50% - 0.5rem);
  }

  .location-tabs {
    justify-content: center;
  }

  .tab-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .hero-content {
    padding: 1rem;
  }

  .right-side {
    margin-bottom: 0.5rem;
  }

  .logo {
    width: 8rem;
    height: 8rem;
    margin-top: -0.5rem;
  }

  .profile-image {
    width: 10rem;
    height: 10rem;
  }

  .hero-quote {
    font-size: 1.25rem;
  }

  .content-half {
    padding: 1rem;
  }

  .portfolio-image-container {
    width: 100%;
    margin-bottom: 1rem;
  }

  .content-half.portfolio-text,
  .content-half.portfolio-gallery {
    padding: 1rem;
  }
}

/* ==========================================================================
   Navigation Menu
   ========================================================================== */
.nav-menu {
  position: fixed;
  top: 1rem;
  width: 100%;
  z-index: 1000;
  padding: 1rem 0;
  transition: all 0.3s ease;
}

.nav-menu.scrolled {
  background-color: var(--color-primary);
  top: 0;
  padding: 0.5rem 0;
}

.menu-items {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-items li {
  color: var(--color-white);
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
  font-size: 1.2rem;
  letter-spacing: 1px;
}

.menu-items li:hover {
  color: var(--color-secondary);
}

.menu-toggle {
  display: none;
  color: var(--color-white);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 1rem;
}

/* Update mobile menu styles */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1001;
  }

  .menu-items {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--color-primary);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-100%);
    transition: transform 0.3s ease;
    z-index: 1000;
  }

  .menu-items.active {
    transform: translateY(0);
  }

  .menu-items li {
    padding: 1rem;
    font-size: 1.5rem;
  }

  /* Remove the hero section padding */
  .section-hero {
    padding-top: 0;
  }
}
